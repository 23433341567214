import { WModalButton } from "../../components/button/WToolButton.tsx";
import { WModal, WModalContent } from "../../components/modal/WModal.tsx";
import { useStartAdminImpersonation } from "../../modules/access/impersonation/apiOperations.ts";
import type { GQUserItem } from "./UsersTable.tsx";

interface ImpersonateModalModalProps {
    item: Pick<GQUserItem, "id" | "name">;
}

export function ImpersonateModalContent({ item }: ImpersonateModalModalProps) {
    const startImpersonation = useStartAdminImpersonation();
    const { id, name } = item;

    return (
        <WModalContent
            title="Access User"
            label="Start"
            autoFocus
            modalAction={async () => {
                await startImpersonation.mutateAsync({
                    body: {
                        target_user_id: Number(id),
                        description: "For impersonating user",
                        is_admin: true,
                    },
                    access_owner: name,
                });
            }}
        >
            <p className="pb-4">You are about to access user "{name}"</p>
            <p className="pb-4">
                The entire user interface will switch and act as you were that user. Once you are finished you can stop
                the session by clicking “Back to your account” button on the header bar and the user interface will
                switch back to your account.
            </p>
            <p>Keep in mind that all the actions will be tracked and can be traced back to you.</p>
        </WModalContent>
    );
}

export function ImpersonateBlock(props: ImpersonateModalModalProps) {
    return (
        <WModal button={<WModalButton inTable label="Impersonate" size="xs" icon="jp-swap-icon" />}>
            <ImpersonateModalContent {...props} />
        </WModal>
    );
}
