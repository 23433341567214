import type { FieldApi } from "@tanstack/react-form";

// TODO: Get rid of `any` types here
export function ariaTanstackFieldProps<
    TFieldApi extends FieldApi<
        any,
        any,
        any,
        any,
        any,
        any,
        any,
        any,
        any,
        any,
        any,
        any,
        any,
        any,
        any,
        any,
        any,
        any
    >,
>(api: TFieldApi) {
    const {
        handleChange,
        handleBlur,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        state: { value, meta },
    } = api;
    return {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        value,
        onBlur: handleBlur,
        onChange: handleChange,
        isInvalid: meta.errors.length > 0,
    };
}
